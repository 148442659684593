import React, { useEffect, useState } from "react";
import Login from "./components/Login";

import { useTimer } from "react-use-precision-timer";

import { useAuthContext } from "./context/auth";
import PortraitDefault from "./components/PortraitDefault";
import LandscapeDefault from "./components/LandscapeDefault";
import IceBreaking from "./components/Programs/IceBreaking/IceBreaking";
import DynamicStretching from "./components/Programs/DynamicStretching/DynamicStretching";
import LandscapeDemo from "./components/Programs/Demo/LandscapeDemo";
import PortraitDemoA from "./components/Programs/Demo/PortraitDemoA";
import WorkoutType1 from "./components/Programs/Workout/WorkoutType1";
import WorkoutType2 from "./components/Programs/Workout/WorkoutType2";
import LandscapeTeamWorkout from "./components/Programs/TeamWorkout/LandscapeTeamWorkout";
import Admin from "./components/Admin/Admin";
import Start from "./components/Programs/Start/Start";
import Loading from "./components/Loading";
import PortraitDemoB from "./components/Programs/Demo/PortraitDemoB";
import Rest from "./components/Rest";
import PortraitTeamWorkout from "./components/Programs/TeamWorkout/PortraitTeamWorkout";
import CoolDown from "./components/Programs/CoolDown/CoolDown";
import LoadingPortrait from "./components/LoadingPortrait";
import WorkoutType3 from "./components/Programs/Workout/WorkoutType3";
import LandscapeRow from "./components/Programs/Row/LandscapeRow";
import PortraitRow from "./components/Programs/Row/PortraitRow";

function App() {
  const {
    isAuthenticated,
    loading,
    did,
    step,
    circuitProgram,
    weightProgram,
    setStep,
    row,
  } = useAuthContext();
  if (loading)
    return did === "1" || did === "0" ? (
      <Loading key={"loading"} />
    ) : (
      <LoadingPortrait key={"loading-portrait"} />
    );
  if (!isAuthenticated) return <Login />;

  const getScreen = () => {
    if (did === "0" && step === 0) {
      return <Admin key={"admin"} />;
    }
    const firstWeight = <PortraitDemoA key={"first-weight"} />;
    const secondWeight = <PortraitDemoB key={"second-weight"} />;

    const firstCircuit =
      did === "L1" || did === "L6" ? (
        <PortraitDemoA key={"first-circuit-a"} />
      ) : (
        <PortraitDemoB key={"first-circuit-b"} />
      );
    const secondCircuit =
      did === "L1" || did === "L6" ? (
        <PortraitDemoA key={"second-circuit-a"} />
      ) : (
        <PortraitDemoB key={"second-circuit-b"} />
      );

    const samePages = [
      did === "1" || did === "0" ? (
        <LandscapeDefault key={"landscape-default"} />
      ) : (
        <PortraitDefault key={"portrait-default"} />
      ),
      did === "1" || did === "0" ? (
        <Start key={"start"} />
      ) : (
        <PortraitDefault key={"portrait-default"} />
      ),
      did === "1" || did === "0" ? (
        <IceBreaking key={"ice-breaking"} />
      ) : (
        <PortraitDefault key={"portrait-default"} />
      ),
      did === "1" || did === "0" ? (
        <Rest nowStep={2} time={4} key={"rest1"} />
      ) : (
        <PortraitDefault key={"portrait-default"} />
      ),
      did === "1" || did === "0" ? (
        <DynamicStretching key={"dynamic-stretching"} />
      ) : (
        <PortraitDefault key={"portrait-default"} />
      ),
    ];

    const otherSamePages = [
      did === "1" || did === "0" ? (
        <CoolDown key={"cool-down"} />
      ) : (
        <PortraitDefault key={"portrait-default"} />
      ),
    ];

    const teamWorkOuts =
      circuitProgram?.isTeamWorkOutAvailable === false ||
      weightProgram?.isTeamWorkOutAvailable === false
        ? []
        : [
            row === undefined ? (
              did === "1" || did === "0" ? (
                <LandscapeTeamWorkout key={"landscape-team-workout"} />
              ) : (
                <PortraitTeamWorkout key={"portrait-team-workout"} />
              )
            ) : did === "1" || did === "0" ? (
              <LandscapeRow key={"landscape-row"} />
            ) : (
              <PortraitRow key={"portrait-row"} />
            ),
          ];

    const weightPages = [
      ...samePages,
      did === "1" || did === "0" ? (
        <LandscapeDemo key={"landscape-demo"} />
      ) : did === "L1" || did === "L6" ? (
        firstWeight
      ) : (
        secondWeight
      ),
      did === "1" || did === "0" ? (
        <Rest nowStep={4} time={4} key={"rest2"} />
      ) : (
        firstWeight
      ),
      did === "1" || did === "0" ? (
        <WorkoutType1 key={"workout-type-1"} />
      ) : (
        firstWeight
      ),
      did === "1" || did === "0" ? (
        <Rest nowStep={4} key={"rest3"} />
      ) : (
        firstWeight
      ),
      did === "1" || did === "0" ? (
        <WorkoutType1 secondRound key={"workout-type-1-second-round"} />
      ) : (
        firstWeight
      ),
      did === "1" || did === "0" ? (
        <Rest nowStep={4} key={"rest4"} />
      ) : (
        secondWeight
      ),
      did === "1" || did === "0" ? (
        <WorkoutType1 key={"workout-type-1-second"} />
      ) : (
        secondWeight
      ),
      did === "1" || did === "0" ? (
        <Rest nowStep={4} key={"rest5"} />
      ) : (
        secondWeight
      ),
      did === "1" || did === "0" ? (
        <WorkoutType1 secondRound key={"workout-type-1-second-round-2"} />
      ) : (
        secondWeight
      ),
      ...teamWorkOuts,
      ...otherSamePages,
    ];

    const circuitPages = [
      ...samePages,
      did === "1" || did === "0" ? (
        <LandscapeDemo key={"landscape-demo"} />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <Rest nowStep={4} time={4} key={"rest6"} />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <WorkoutType2 key={"workout-type-2"} />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <Rest nowStep={4} key={"rest7"} />
      ) : (
        secondCircuit
      ),
      did === "1" || did === "0" ? (
        <WorkoutType2 second key={"workout-type-2-second"} />
      ) : (
        secondCircuit
      ),
      ...teamWorkOuts,
      ...otherSamePages,
    ];

    const newCircuitPages = [
      ...samePages,
      did === "1" || did === "0" ? (
        <LandscapeDemo key={"landscape-demo"} />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <Rest nowStep={4} time={4} key={"rest8"} />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <WorkoutType3 round={1} key={11} />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <WorkoutType3 round={2} key={22} />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <WorkoutType3 round={3} key={33} />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <Rest nowStep={4} time={60} key={"rest9"} />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <WorkoutType3 round={1} key={21} second />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <WorkoutType3 round={2} key={22} second />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <WorkoutType3 round={3} key={23} second />
      ) : (
        firstCircuit
      ),
      ...teamWorkOuts,
      ...otherSamePages,
    ];

    if (
      circuitProgram?.programType === "BOOSTER"
        ? circuitPages.length <= step
        : circuitProgram?.programType === "CIRCUIT"
        ? newCircuitPages.length <= step
        : weightPages.length <= step
    ) {
      setStep(0);
      return did === "1" || did === "0" ? (
        <LandscapeDefault key={"landscape-default"} />
      ) : (
        <PortraitDefault key={"portrait-default"} />
      );
    }

    return circuitProgram?.programType === "BOOSTER"
      ? circuitPages[step]
      : circuitProgram?.programType === "CIRCUIT"
      ? newCircuitPages[step]
      : weightPages[step];
  };
  return <>{getScreen()}</>;
}

export default App;
